<template>  
  <div v-resize="onResize">    
    <div style="background:#F9F9F9">
      <v-progress-linear :active="loading" :indeterminate="loading" absolute bottom color="blue accent-4"></v-progress-linear>      
      <div style="background:#FFFFFF; height:35px;" class="d-flex">
        <ul>
          <li class="tab ml-n3 pt-1 pl-3 pr-3" :style="isMapMode?'background: #F9F9F9; border-bottom: none;':''" @click="changeTab(true)">MAP</li>
          <li class="tab ml-0 pt-1 pl-3 pr-3" :style="!isMapMode?'background: #F9F9F9; border-bottom: none;':''" @click="changeTab(false)">GRAPH</li>
        </ul>
        
        <v-spacer></v-spacer>          
        <div class="d-flex" style="align-items : center;" >          
          <div >
            <v-icon v-if="bNotiSound" big class="mt-0" @click="goNotiSound">mdi-volume-high</v-icon>
            <v-icon v-else big class="mt-0" @click="goNotiSound">mdi-volume-off</v-icon>
          </div>
          <div v-if="iAlertCount == 0" class="d-flex ml-3" style="align-items : center;" >
            <div class="grey--text caption">호출</div>
            <div class="grey--text headline font-weight-black ml-2 mr-4">{{iAlertCount}}</div>            
          </div>
          <div v-else class="d-flex ml-3" style="align-items : center;" @click="goSOS()">
            <div class="red--text caption">호출</div>
            <div class="red--text headline font-weight-black ml-2 mr-4 heartbeat2">{{iAlertCount}}</div>            
          </div>
        </div>
      </div>      
      <v-divider></v-divider>     

      <div class="pa-3" style="overflow:auto; height: calc(100vh - 117px);">
        <v-row class="ma-0 mt-n2 ml-n2">
          <v-col cols="12" md="4" sm="12">
            <v-card class="pa-3 card_style rounded-lg" elevation="7">
              <div class="title_card">근로자 현황<v-btn icon class="mt-n1"><v-icon>mdi-chevron-right</v-icon></v-btn></div>
              <div class="ma-3 mt-1">
                <table>
                  <tbody>
                    <tr>
                      <td class="td">실시간 근로자수</td>
                      <td class="td ARight d-flex"> <v-spacer></v-spacer><p class="td_bold blue--text">{{UserCountInfo.fRealtimeCount}}</p><p class="ml-1 text-caption mt-2">명</p></td>                      
                    </tr>                    
                  </tbody>
                </table>
                <div class="ma-3 mt-n7">
                  <v-chart v-if="isCardShow" class="chart_user" :option="option_user" />                
                </div>      
              </div>
            </v-card>
          </v-col>  
          <!-- <v-col cols="12" md="6" sm="12">
            <v-card class="pa-3 card_style rounded-lg" elevation="7">
              <div class="title_card">T/C 현황<v-btn icon class="mt-n1"><v-icon>mdi-chevron-right</v-icon></v-btn></div>              
              <v-row class="ma-0 mt-0">                
                <v-col class="12" md="8" sm="12">
                  
                  <div class="d-flex"><img class="mr-1" src="@/assets/TC.svg" style="height:25px; opacity: 0.6;"/>타워크레인({{zTC_Info.length}})</div>
                  <div class="d-flex mt-2 pb-3" style="overflow-x: auto; overflow-y: hidden; ">
                    <div v-for="(item, i) in zTC_Info" :key="'TC' + i" class="tc_outline mr-2">
                      <div style="margin-top: 18px;">                      
                        <img src="@/assets/TC.svg" style="height:80px; opacity: 0.2;"/>
                        <div class="d-flex" style="position: relative; right:-50px; top:-50px;"><p class="text-h3 grey--text">{{item.cNm}}</p><p class="mt-5 grey--text">호기</p></div>                      
                      </div>
                    </div>                    
                  </div>
                </v-col>
                <v-col class="12" md="4" sm="12"> 
                  <div class="d-flex"><img class="mr-1" src="@/assets/Air.svg" style="height:25px; opacity: 0.4;"/>풍속계({{zWind_Info.length}})</div>
                  <div class="mr-0 mt-2">
                    <div style="width:inherit;" class="d-flex tc_outline pa-3">                      
                      <img class="mt-2" src="@/assets/Air.svg" style="height:75px; opacity: 0.2;"/>                      
                      <div class="mt-3 ml-n2" style="width:100%;">
                        <table>
                          <tbody>
                            <tr>
                              <td class="td">순간</td>
                              <td class="td ARight d-flex"> <v-spacer></v-spacer><p class="td_bold red--text">{{zWind_Info[0].fMaxData}}</p><p class="ml-1 text-caption mt-2">m/s</p></td>                      
                            </tr>
                            <tr>
                              <td class="td">평균</td>
                              <td class="td ARight d-flex"> <v-spacer></v-spacer><p class="td_bold blue--text">{{zWind_Info[0].fAvgData}}</p><p class="ml-1 text-caption mt-2">m/s</p></td>                      
                            </tr>                            
                          </tbody>
                        </table> 
                      </div>
                    </div>
                    </div>
                </v-col>
              </v-row>
            </v-card>                          
          </v-col>   -->
          <v-col cols="12" md="4" sm="12">
            <v-card class="pa-3 card_style rounded-lg" elevation="7">
              <div class="title_card">기상센서(AWS)<v-btn icon class="mt-n1"><v-icon>mdi-chevron-right</v-icon></v-btn></div>
              <div class="ma-3 mt-2">
                <table>
                  <tbody>
                    <tr>
                      <td class="td grey--text">온/습도</td>
                      <td class="td ARight d-flex"> <v-spacer></v-spacer><p class="td_bold grey--text">{{AWS_Info.fTemperature}}</p><p class="ml-1 text-caption mt-2">°C</p><p class="td_bold grey--text ml-2"> / {{AWS_Info.fHumidity}}</p><p class="ml-1 text-caption mt-2">%</p></td>                      
                    </tr>
                    <tr>
                      <td class="td grey--text">강우량</td>
                      <!-- <td class="td ARight d-flex"> <v-spacer></v-spacer><p class="td_bold grey--text"> {{AWS_Info.fFall}}</p><p class="ml-1 text-caption mt-2">mm</p></td>                       -->
                      <td class="td ARight d-flex"> <v-spacer></v-spacer><p class="td_bold grey--text"> {{AWS_Info.fFall}}</p><p class="ml-1 text-caption mt-2"></p></td>                      
                    </tr>
                    <tr>
                      <td class="td grey--text">풍속(풍향)</td>
                      <td class="td ARight d-flex"> <v-spacer></v-spacer><p class="td_bold grey--text">{{AWS_Info.fWindSpeed}}</p><p class="ml-1 text-caption mt-2">m/s</p><p class="td_bold grey--text">({{AWS_Info.fWindDirection}})</p></td>                      
                    </tr>
                  </tbody>
                </table>
              </div>
              <div :class="'text-h5 mt-2 pa-1 '+ getErrorCSS(AWS_Info.cState)" :style="goAWS_StateCSS(AWS_Info.cState)">{{AWS_Info.cState}}</div>
            </v-card>
          </v-col>  

          <v-col cols="12" md="4" sm="12">
            <v-card class="pa-3 card_style rounded-lg" elevation="7">
              <div class="title_card">가스현황({{iNowGasDevice_Info+1}}/{{zGasDevice_Info.length}})<v-btn :disabled = "iNowGasDevice_Info == 0" icon class="mt-n1" @click="goGasDeviceInfo(-1);"><v-icon>mdi-chevron-left</v-icon></v-btn><v-btn :disabled = "iNowGasDevice_Info+1 == zGasDevice_Info.length"  icon class="mt-n1" @click="goGasDeviceInfo(1)"><v-icon>mdi-chevron-right</v-icon></v-btn></div>
              <v-chip label small dark class="ml-1 mt-n4 mb-n5" @click="goSensorState(zGasDevice_Info[iNowGasDevice_Info].cSensorId)">{{zGasDevice_Info[iNowGasDevice_Info].cCaption}}</v-chip>
              <div class="ma-3 mt-1">
                <table >
                  <tbody>
                    <tr v-for="(item, i) in zGasDevice_Info[iNowGasDevice_Info].Data" :key="i + 'GGG'">
                      <td class="td grey--text" v-html="item.cCaption"></td>
                      <td class="td ARight d-flex td_bold"> <v-spacer></v-spacer><p :class="goGasDeviceFontClass(item.cState)">{{item.fRealData}}</p><p :class="'ml-1 ' + goGasDeviceFontClass(item.cState) + ' text-caption mt-2'">{{item.cUnit}}</p></td>                       
                      <td :class="getErrorCSS2(item.cState)" style="width:55px"><v-chip :color="goGasDeviceColorClass(item.cState)" label small dark>{{item.cState}}</v-chip></td>
                    </tr>
                  </tbody>
                </table>
              </div>                        
            </v-card>

            <!-- <v-card class="mt-6 pa-3 card_style rounded-lg" elevation="7">
              <div class="title_card">소음/진동<v-btn icon class="mt-n1"><v-icon>mdi-chevron-right</v-icon></v-btn></div>
              <div class="ma-3 mt-n2 d-flex">
                <v-chart v-if="isCardShow" class="chart_noise" :option="option_noise" />                
                <v-chart v-if="isCardShow" class="chart_noise" :option="option_vibration" />                
              </div>               
            </v-card> -->
          </v-col>  

          <v-col cols="12" md="12" sm="12">            
            <v-card class="pa-3 card_style rounded-lg" style="height:485px;" elevation="7">
              <div class="title_card">가스 관제 현황 <v-btn icon class="mt-n1"><v-icon>mdi-chevron-right</v-icon></v-btn></div>
              <div class="d-flex mt-2 ml-3"><img class="mr-1" src="@/assets/Gas.svg" style="height:25px; opacity: 0.3;"/>가스감지기({{zApGasInfo.length}})
                <div v-for="(item, i) in zApGasInfo" :key="'ApGas'+i" class="ml-2" :style="'margin-top: -2px; border-radius: 7px; border: 3px solid '+(item.cEMERGENCY_Lev=='0'?'#FFFFFF':item.cEMERGENCY_Lev=='1'?'#FF9800':'#FF0000')+';'" @click="setGasApIdx(i); goSensorState(item.cSensorId);">                  
                  <v-chip class="mt-n1" label small :color=" iNowApGasIdx == i? 'black':'grey'" dark >{{item.cSensorId2}}</v-chip>
                </div>                
                
                <!-- <v-chip class="ml-2" label small color="orange" dark>2번</v-chip> -->
              </div>
              <div class="mt-3">
                <table>
                  <tbody>
                    <tr>
                      <td v-for="(item, i) in zGasInfo" :key="'Gas'+i"  >
                        <div :class="'gas_card ma-1 ' + getErrorCSS(item.cState)" :style="getGasBoxStyle(item.cState)" >
                          <div class="td_bold mt-3" :style="getGasTitleStyle(item.cState)">{{item.cNm}}</div>
                          <div :style="getGasTitleStyle(item.cState)">{{item.cInfo}}</div>                  
                          <div align="center" class="mt-13 mb-4">
                            <Progress 
                              :transitionDuration="1300"                              
                              :radius="55" 
                              :strokeWidth="15" 
                              :value="item.fData">                              
                            </Progress>
                          </div>
                          <div :style="getGasDataCss(item.cState)">{{item.fRealData.toString()}}<p style="font-size:15px; margin-top: -20px;">{{item.cUnit}}</p></div>
                          <div class="td_bold" :style="getGasTitleStyle(item.cState) + 'font-size:30px;'">{{item.cState}}</div>                          
                          <div class="text-caption" :style="getGasTitleStyle(item.cState)">2023-04-03 22:50:30</div>                  
                        </div>
                      </td>
                    </tr>                    
                  </tbody>
                </table>
                
              </div>
            </v-card>        
          </v-col>            

          <!-- <v-col cols="12" md="3" sm="12">
            <v-card class="pa-3 card_style rounded-lg" elevation="7">
              <div class="title_card">흙막이 현황({{getTotalSoilCount()}})<v-btn icon class="mt-n1"><v-icon>mdi-chevron-right</v-icon></v-btn></div>              
              <div class="ma-3 mt-0">
                <table>
                  <tbody>
                    <tr v-for="(item, i) in zSoilInfo" :key="i">
                      <td class="td grey--text" v-html="item.cNm"></td>
                      <td class="td ARight d-flex td_bold"> <v-spacer></v-spacer><p :class="goGasDeviceFontClass(item.cState)">{{item.fQty}}</p><p :class="'ml-1 ' + goGasDeviceFontClass(item.cState) + ' text-caption mt-2'">개</p></td> 
                      <td :class="'td ARight grey--text ' + getErrorCSS2(item.cState)" style="width:55px"><v-chip :color="goGasDeviceColorClass(item.cState)" label small dark>{{item.cState}}</v-chip></td>
                    </tr>
                  </tbody>
                </table>
              </div>              
            </v-card>

            <v-card class="mt-6 pa-3 card_style rounded-lg" elevation="7">
              <div class="title_card">장비현황<v-btn icon class="mt-n1"><v-icon>mdi-chevron-right</v-icon></v-btn></div>              
              <div class="ma-3 mt-1">
                <v-chart v-if="isCardShow" class="chart" :option="option" />                
              </div>              
            </v-card>
          </v-col>   -->
        </v-row>
      </div>
    </div> 
    <div class="alert_box" v-show="iAlertCount != 0 && isShowAlert" id="blinking" @click="goSOS()">      
      <v-icon large color="white" class="mr-3">call</v-icon>비상호출 상황이 발생하였습니다!
    </div>   

    <div class="event_box" v-show="isAlertEvent != 0 && isShowAlert" id="blinking" @click="showDevice('EV03')">
      <v-icon large color="white" class="mr-3">mdi-alert</v-icon>비인가자가 접근하였습니다!
    </div>   

    <AlertShowSel ref="AlertShowSelRef" @onSelect="onSelect" />    
    <SetSOS ref="SetSOSRef" @onSetSOS="onSetSOS" />    
    <SetEventCheck ref="SetEventCheckRef" @onSetEventCheck="onSetEventCheck" />      


    

  </div>  
</template>

<script>
import BasicInfo from "@/BasicInfo.js";
import AlertShowSel from "@/components/AlertShowSel.vue";
import SetSOS from "@/components/SetSOS.vue";

import SetEventCheck from "@/components/SetEventCheck.vue";
import Util from "../Util.js";
import axios from "axios";
import { mapState } from "vuex";
import EventBus from '@/eventBus.js';
import router from "../router";
import Progress from "easy-circular-progress/src/index.vue";
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { GridComponent } from 'echarts/components';
import { PieChart, LineChart, BarChart, GaugeChart } from "echarts/charts";


import {
  TitleComponent,
  TooltipComponent,
  LegendComponent
} from "echarts/components";
import VChart from "vue-echarts";

const zType_safe = ["안전", "일반"];
const zType_danger = ["위험", "3차경보", "2차경보"];
const zType_warning = ["경고", "1차경보"];

use([
  CanvasRenderer,
  GridComponent,
  PieChart,
  LineChart,
  BarChart,
  GaugeChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,  
]);

export default {
  components: {
    AlertShowSel,
    SetSOS,
    SetEventCheck,    
    Progress,
    VChart,
  },
  data: () => ({   
    isSensorInfo : true,
    isSideBar : true,
    isCardShow : true,
    isMapMode : false,
    zAPList : [],
    cNowSearchKeyWord : "",
    mapWidth : 100,
    mapHeight : 100,
    bNotiSound : true,
    bNotiSoundReq : true,
    isWindowsEvent : false,
    isShowAlert : false,      
    
    TimerId : null,     
    zEventInfo: [],
    fRateSize : 1, 
    isRunCheck : false,
    isMoreInfo : false,
    zMapInfo : [],
    open: [],    

    selection : [],    
    isAlert : false,
    iAlertCount : 0,
    iAlertCheckCount : 0,
    sNowMapId : "",
    sNowBuildingId : "",
    sNowMapGroupId : "",
    bNowSelAble : true,
    loading : false,    
    Records: [],
    items: [],        
    isAlertEvent : false,
    selection2 : ["2"],    
    items2: [
      {id:"1", name:"aaa1", iLev : 0, iCount : 20,},
      {id:"2", name:"aaa2", iLev : 0, iCount : 20,},
      {id:"3", name:"aaa3", iLev : 0, iCount : 20,},{id:"4", name:"aaa4", iLev : 0, iCount : 20,},
      {id:"5", name:"aaa5", iLev : 0, iCount : 20,},
    ],        
    zoom: BasicInfo.Map_Zoom_Default,
    isReqMode: BasicInfo.IsReqMode,            
    isPOS_Near : BasicInfo.IsPOS_Near,    
    
    nowPOS : {
      left : 0, top : 0
    },    
    imgUrl : BasicInfo.FILE_API + "Files/NoImage.svg",
    zApPos: [],    
    zLog : [],

    ReqEnterInfo: {
      iNorReqCount : 0, iNorOKCount : 0, iNorNowReqCount : 0, 
      iJobReqCount : 0, iJobROKCount : 0, iJobNowReqCount : 0, 
    },
    iNowApGasIdx : -1,
    zApGasInfo:[],
    zGasD_Limit:[40,10,10,100],    
    zGasInfo: [
      { cNm : "산소", cInfo : "(안전범위:18.5~22.5%)", fData: 20.2, fRealData : 20.2, cUnit:"%", cState : "안전", cDatetime : "2023-04-05 10:00:00"},
      { cNm : "일산화탄소",  cInfo : "(안전범위:10ppm미만)", fData: 100, fRealData : 20.8, cUnit:"ppm",  cState : "위험", cDatetime : "2023-04-05 10:00:00"},
      { cNm : "황화수소",  cInfo : "(안전범위:10ppm미만)", fData: 70, fRealData : 7, cUnit:"ppm",cState : "경고", cDatetime : "2023-04-05 10:00:00"},
      { cNm : "메탄",  cInfo : "(안전범위:10%미만)", fData: 10.2, fRealData : 1.02, cUnit:"%",cState : "안전", cDatetime : "2023-04-05 10:00:00"},
    ],

    UserCountInfo : {fRealtimeCount : 11, fNewCount : 1, fOldCount : 3, fForeignerCount : 4},

    zTC_Info : [
      {cNm : "1", cState : "안전"},
      {cNm : "2", cState : "안전"},
      {cNm : "3", cState : "경고"},
      {cNm : "4", cState : "위험"},
    ],

    zWind_Info : [
      {cNm : "1", fMaxData : 8.5, fAvgData : 3.5},
    ],
    iNowGasDevice_Info : 0,
    zGasDevice_Info : [
      {
        Caption : "1번 가스센서 > 현장 / 지하 / H2_T",
        cSensorId : "",
        Data: [
          { cCaption: '<div class="d-flex mt-1 mb-n2">O<p style="font-size:5px; margin-top: 5px;">2</p></div>', cNm : "산소", cInfo : "(안전범위:18.5~22.5%)", fData: 20.2, fRealData : 20.2, cUnit:"%", cState : "안전", cDatetime : "2023-04-05 10:00:00"},
          { cCaption: '<div class="d-flex mt-1 mb-n2">H<p style="font-size:5px; margin-top: 5px;">2</p>S</div>', cNm : "일산화탄소",  cInfo : "(안전범위:10ppm미만)", fData: 100, fRealData : 20.8, cUnit:"ppm",  cState : "위험", cDatetime : "2023-04-05 10:00:00"},
          { cCaption: '<div class="d-flex">CO</div>', cNm : "황화수소",  cInfo : "(안전범위:10ppm미만)", fData: 50, fRealData : 5, cUnit:"ppm",cState : "경고", cDatetime : "2023-04-05 10:00:00"},
          { cCaption: '<div class="d-flex mt-1 mb-n2">CH<p style="font-size:5px; margin-top: 5px;">4</p></div>', cNm : "메탄",  cInfo : "(안전범위:10%미만)", fData: 20.2, fRealData : 2.02, cUnit:"%",cState : "안전", cDatetime : "2023-04-05 10:00:00"},
        ]
      },
      {
        Caption : "2번 가스센서 > 현장 / 1F / H2_T",
        cSensorId : "",
        Data: [
          { cCaption: '<div class="d-flex mt-1 mb-n2">O<p style="font-size:5px; margin-top: 5px;">2</p></div>', cNm : "산소", cInfo : "(안전범위:18.5~22.5%)", fData: 20.2, fRealData : 50.2, cUnit:"%", cState : "위험", cDatetime : "2023-04-05 10:00:00"},
          { cCaption: '<div class="d-flex mt-1 mb-n2">H<p style="font-size:5px; margin-top: 5px;">2</p>S</div>', cNm : "일산화탄소",  cInfo : "(안전범위:10ppm미만)", fData: 50, fRealData : 5.8, cUnit:"ppm",  cState : "경고", cDatetime : "2023-04-05 10:00:00"},
          { cCaption: '<div class="d-flex">CO</div>', cNm : "황화수소",  cInfo : "(안전범위:10ppm미만)", fData: 20, fRealData : 2, cUnit:"ppm",cState : "안전", cDatetime : "2023-04-05 10:00:00"},
          { cCaption: '<div class="d-flex mt-1 mb-n2">CH<p style="font-size:5px; margin-top: 5px;">4</p></div>', cNm : "메탄",  cInfo : "(안전범위:10%미만)", fData: 20.2, fRealData : 2.02, cUnit:"%",cState : "안전", cDatetime : "2023-04-05 10:00:00"},
        ]
      },
    ],

    AWS_Info : {
      fTemperature : 26.20, //온도
      fHumidity : 30, //습도
      fFall : 30, //강우량
      fWindSpeed : 0.4,
      fWindDirection : "북동",
      // cState : "경고"
      // cState : "위험"
      cState : "일반"
    },

    zSoilInfo : [
      {cNm : "지중경사계", fQty : 1, cState : "안전"},
      {cNm : "지하수위계", fQty : 8, cState : "위험"},
      {cNm : "지표침하계", fQty : 5, cState : "경고"},
      {cNm : "변형율계",  fQty : 1, cState : "안전"},
      {cNm : "E/A하중계", fQty : 1, cState : "안전"},
    ],

    option_user : {
      xAxis: {
        type: 'category',
        data: ['신규', '고령자', '외국인']
      },
      label: {
        show: true,
      },
      yAxis: {
        type: 'value'
      },
      series: [
        {
          data: [
          {
            value: 15,
            itemStyle: {
              color: '#3D7EEF'
            }
          },
          {
            value: 20,
            itemStyle: {
              color: '#EACF28'
            }
          },
          {
            value: 54,
            itemStyle: {
              color: '#FF5447'
            }
          },
          ],
          type: 'bar'
        }
      ]
    },
    option_noise : {      
      series: [
        {
          type: 'gauge',
          max: 100,
          axisLine: {
            lineStyle: {
              width: 10,
              color: [
                [0.3, '#888888'],
                [0.7, '#EACF28'],
                [1, '#fd666d']
              ]
            }
          },
          pointer: {
            itemStyle: {
              color: 'inherit'
            }
          },
          axisTick: {
            distance: -15,
            length: 8,
            lineStyle: {
              color: '#fff',
              width: 1
            }
          },
          splitLine: {
            distance: -10,
            length: 100,
            lineStyle: {
              color: '#fff',
              width: 2
            }
          },
          axisLabel: {
            color: 'inherit',
            distance: 40,
            fontSize: 0
          },
          detail: {
            valueAnimation: true,
            formatter: '{value}dB',
            color: 'inherit'
          },
          data: [
            {
              value: 75
            }
          ]
        }
      ]
    },
    
    option_vibration : {      
      series: [
        {
          type: 'gauge',
          max: 10,
          axisLine: {
            lineStyle: {
              width: 10,
              color: [
                [0.3, '#888888'],
                [0.7, '#EACF28'],
                [1, '#fd666d']
              ]
            }
          },
          pointer: {
            itemStyle: {
              color: 'inherit'
            }
          },
          axisTick: {
            distance: -15,
            length: 8,
            lineStyle: {
              color: '#fff',
              width: 1
            }
          },
          splitLine: {
            distance: -10,
            length: 100,
            lineStyle: {
              color: '#fff',
              width: 2
            }
          },
          axisLabel: {
            color: 'inherit',
            distance: 40,
            fontSize: 0
          },
          detail: {
            valueAnimation: true,
            formatter: '{value}dB',
            color: 'inherit'
          },
          data: [
            {
              value: 3
            }
          ]
        }
      ]
    },

    option : {
      tooltip: {
        trigger: 'item'
      },
      legend: {
        top: '0%',
        left: 'center',
        // doesn't perfectly work with our tricks, disable it
        selectedMode: true,
      },
      series: [
        {
          name: '',
          type: 'pie',
          radius: ['40%', '70%'],
          center: ['50%', '70%'],
          // adjust the start angle
          startAngle: 180,
          label: {
            show: true,
            formatter(param) {
              // correct the percentage
              return param.name + ' (' + param.percent * 2 + '%)';
            }
          },
          data: [
            { value: 5, name: '덤프트럭' },
            { value: 7, name: '굴삭기' },
            { value: 20, name: '트럭' },
            { value: 4, name: 'TC' },            
            {
              // make an record to fill the bottom 50%
              value: 5+7+20+4,
              itemStyle: {
                // stop the chart from rendering this piece
                color: 'none',
                decal: {
                  symbol: 'none'
                }
              },
              label: {
                show: false
              }
            }
          ]
        }
      ]
    },
  }),

  computed: {
    ...mapState(["UserInfo", "ProgramPermissionInfo"]),    
  },
  mounted () {
    this.isRunCheck = true;
    
    this.bNotiSound = localStorage.getItem("cNotiSound") != 'N';
    this.bNotiSoundReq = localStorage.getItem("cNotiSoundReq") != 'N';
    

    this.onResize()
    this.initialize();
    this.getEvent();
    

    this.SetTimeDashvboard();
    this.SetTimeApGasDisplay();

    this.TimerId = setInterval(this.ShowAlert, 8000);

    EventBus.$on("onMapPosDashboard", (cApId) => { 
      setTimeout(() => {
        // console.log("onMapPosDashboard",cApId);
          for (let i = 0; i < this.zAPList.length; i++) {
            if (this.zAPList[i].cApId == cApId) {
              this.$refs.MapWindowViewRef.SelectFloorPOS("10" + this.zAPList[i].cBuildingId, this.zAPList[i].cMapGroupId + this.zAPList[i].cBuildingId + this.zAPList[i].cMapId);
              break;
            }
          }        
      }, 500);
    });
    EventBus.$on("onDashboard", () => {                
      this.onResize()
      this.initialize();
      this.getEvent();
    });
  },

  beforeDestroy(){
    EventBus.$off('onMapPosDashboard');
    EventBus.$off('onDashboard');
    this.isRunCheck = false;    
    clearInterval(this.TimerId);
  },
  created() {        
  },  

  methods: {
    bCheckAlarm(sId){        
      for (var i = 0; i < this.zEventInfo.length; i++) {        
        if (this.zEventInfo[i].cEventId == sId) {          
          return this.zEventInfo[i].bCheck;
        }        
      }
      return false;
    },
    getEvent() {      
      this.zEventInfo = [];

      var posData = {
        cUserId: this.$store.state.UserInfo.UserId
      };
      axios
        .post(BasicInfo.UIL_API + "GetEventInfo", posData, {
          headers: {                        
            Authorization: this.$store.state.UserInfo.Token,
          }
        })
        .then(res => {
          if (res.data.ResultCd == "44") { EventBus.$emit("onTokenExpired"); return; }
                    
          if (res.data.ResultCd === "00") {
            this.zEventInfo = res.data.Result;
            for (var i = 0; i < this.zEventInfo.length; i++) {
              this.zEventInfo[i].bCheck = this.zEventInfo[i].cUse == "Y"; 
            }            

            this.checkData();
          } else {
            EventBus.$emit("onShowMsg",true,  "(" + res.data.ResultCd + ") " + res.data.Msg);
          }
        })
        .catch(err => {
          EventBus.$emit("onShowMsg",true, "(08)"+err);
        });
    },

    changeTab() {
      router.push({name: "Dashboard"});      
    },        
    getTotalSoilCount(){
      var iCount = 0;
      for (var i = 0; i < this.zSoilInfo.length;i++) {
        iCount = iCount + this.zSoilInfo[i].fQty;
      }
      return iCount;
    },
    goAWS_StateCSS(d){
      if (zType_safe.includes(d)) {
        return "text-align: center; background-color: #26323810; border-radius: 7px; color : #666666; height : 42px;"
      } else  if (zType_danger.includes(d)) {
        return "text-align: center; background-color: #FF382A; border-radius: 7px; color : white; height : 42px;"
      } else  if (zType_warning.includes(d)) {
        return "text-align: center; background-color: #EACF28; border-radius: 7px; color : white; height : 42px;"
      }
    },


    goGasDeviceFontClass(d){
      if (zType_safe.includes(d)) {
        return "grey--text"
      } else  if (zType_danger.includes(d)) {
        return "red--text"
      } else  if (zType_warning.includes(d)) {
        return "orange--text"
      }
    },

    goGasDeviceColorClass(d){
      if (zType_safe.includes(d)) {
        return "grey"
      } else  if (zType_danger.includes(d)) {
        return "red"
      } else  if (zType_warning.includes(d)) {
        return "orange"
      }
    },



    goGasDeviceInfo(d){
      this.iNowGasDevice_Info = this.iNowGasDevice_Info + d;
    },

    getGasDataCss(d) {
      var s = "position: relative; top:-142px; height:0px; font-size:60px; font-weight: bolder;";
      if (zType_safe.includes(d)) {
        return s + "color:#000000AA; text-shadow: -2px 0 white, 0 2px white, 2px 0 white, 0 -2px white;"
      } else  if (zType_danger.includes(d)) {
        return s + "color:#FFFFFFE0; text-shadow: -2px 0 red, 0 2px red, 2px 0 red, 0 -2px red;";
      } else  if (zType_warning.includes(d)) {
        return s + "color:#DABF18; text-shadow: -2px 0 white, 0 2px white, 2px 0 white, 0 -2px white;";        
      }
    },   

    getGasStrokeColor(d) {
      if (zType_safe.includes(d)) {
        return "#000000AA"
      } else  if (zType_danger.includes(d)) {
        return "#FFFFFFE0"
      } else  if (zType_warning.includes(d)) {
        return "#DABF18"
      }
    },
    getGasTitleStyle(d) {
      if (zType_safe.includes(d)) {
        return "color:#000000AA;"
      } else  if (zType_danger.includes(d)) {
        return "color:#FFFFFFCC;"
      } else  if (zType_warning.includes(d)) {
        return "color:#958839;"
      }
    },
    getErrorCSS(d) {
      if (zType_safe.includes(d)) {
        return ""
      } else  if (zType_danger.includes(d)) {
        return "heartbeat"
      } else  if (zType_warning.includes(d)) {
        return ""
      }
    },
    getErrorCSS2(d) {
      if (zType_safe.includes(d)) {
        return ""
      } else  if (zType_danger.includes(d)) {
        return "heartbeat2"
      } else  if (zType_warning.includes(d)) {
        return ""
      }
    },
    getGasBoxStyle(d) {
      if (zType_safe.includes(d)) {
        return "color:#000000AA;"
      } else  if (zType_danger.includes(d)) {
        return "background-color: #FF5447; border: 4px solid #FF0000; box-shadow: 0px 0px 8px 8px #FF000030; "
      } else  if (zType_warning.includes(d)) {
        return "background-color: #FFF7C4; border: 4px solid #EACF28; box-shadow: 0px 0px 8px 8px #EACF2830; "
      }
    },
    getColor(d) {
      if (d === "OK") {
        return "#70bbfe"
      } else if (d === "Error") {
        return "#ff4862"
      } else if (d === "Fatal") {
        return "#ff4862"
      } else if (d === "Warn") {
        return "#f6da6d"        
      } else if (d === "Disconnect") {
        return "#F44336"        
      } else if (d === "ON") {
        return "#70bbfe"
      } else if (d === "OFF") {
        return "#ff4862"
      } else if (d === "") {
        return "#FFFFFF"
      } else {
        return "#444444"
      }
    },
    getFontColor (d) {      
      if (d == 'Y') {
        return 'grey--text';
      } else if(d =='N') {
        return 'red--text';
      } else if (d === "OK") {
        return 'light-blue--text'
      } else if (d === "Error") {
        return 'red--text'
      } else if (d === "Warn") {
        return 'yellow--text text--accent-4'
      } else if (d === "Disconnect") {
        return 'red--text'
      } else {
        return 'gray--text'
      }
    },  
    goNotiSound(){
      this.bNotiSound = !this.bNotiSound;
      localStorage.setItem("cNotiSound", Util.sBoolToStr(this.bNotiSound));
    },
    goNotiSoundReq(){
      this.bNotiSoundReq = !this.bNotiSoundReq;
      localStorage.setItem("cNotiSoundReq", Util.sBoolToStr(this.bNotiSoundReq));
    },
    getClass(apPos) {
      var s = "point_ani box_ap_FontQty";
      if (apPos.cBuildingType == 'N') {      
        if (apPos.bAllow == false) {
          s = "point_ani box_ap_FontQty_red";
        }

        if (apPos.cSel == 'N') {
          s = "point_ani box_ap_FontQty_green";
        }
      } else {
        s = "point_ani box_ap_FontQty_B_nor";
        if (apPos.bAlert) {
          s = "point_ani box_ap_FontQty_B_err";
        }
      }
      
      return s;
    },

    ShowAlert(){
      this.isShowAlert = !this.isShowAlert;      

      if (this.iAlertCount != 0 && this.isShowAlert ) {
        if (this.bNotiSound) {
          var audio = new Audio('EV03.mp3');
          audio.play();
        }
      }

      
      

      setTimeout(() => {
        if (this.bNotiSoundReq) {
          if (this.ReqEnterInfo.iNorNowReqCount != 0) {
            var audio = new Audio('EV04.mp3');
            audio.play();
          }
        }
      }, 4000);
    },

    SelectUser(data){
      console.log(data);
    },


    SetTimeDashvboard(){            
      setTimeout(() => {
        if (!this.isRunCheck) return;
        this.GetMonitoring();
        this.SetTimeDashvboard();      
        this.checkData();
      }, 5000);
    },

    SetTimeApGasDisplay(){            
      setTimeout(() => {
        if (!this.isRunCheck) return;

        if (this.zApGasInfo.length > 0) {
          var idx = this.iNowApGasIdx + 1;
          if (idx >= this.zApGasInfo.length) {
            idx = 0;
          }
          this.setGasApIdx(idx);
        }        
        this.SetTimeApGasDisplay();        
      }, 3000);
    },

    onResize () {                  
      this.isCardShow = false;
      setTimeout(() => {
        this.isCardShow = true;
      }, 100);
    },
    getCSS(checked){
      if (checked == 'Y') {
        return 'logChecked';
      } else {
        return 'logNotChecked';
      }
    },
    getDateCSS(checked){
      if (checked == 'Y') {
        return 'logDateChecked';
      } else {
        return 'logNotDateChecked';
      }
    },

    goEnterPermission(){
      router.push({name: "EnterPermission"});
    },


    setGasApIdx(idx) {
      this.iNowApGasIdx = idx;

      this.zGasInfo[0].fRealData = parseFloat(this.zApGasInfo[this.iNowApGasIdx].cSENS_OXYGEN_Data);
      this.zGasInfo[1].fRealData = parseFloat(this.zApGasInfo[this.iNowApGasIdx].cSENS_CARBON_MONOXIDE_Data);
      this.zGasInfo[2].fRealData = parseFloat(this.zApGasInfo[this.iNowApGasIdx].cSENS_HYDROGEN_Data);
      this.zGasInfo[3].fRealData = parseFloat(this.zApGasInfo[this.iNowApGasIdx].cSENS_METHAN_Data);

      this.zGasInfo[0].cState = this.sGetStateNm(0, this.zApGasInfo[this.iNowApGasIdx].cSENS_OXYGEN_Lev);
      this.zGasInfo[1].cState = this.sGetStateNm(1, this.zApGasInfo[this.iNowApGasIdx].cSENS_CARBON_MONOXIDE_Lev);
      this.zGasInfo[2].cState = this.sGetStateNm(2, this.zApGasInfo[this.iNowApGasIdx].cSENS_HYDROGEN_Lev);
      this.zGasInfo[3].cState = this.sGetStateNm(3, this.zApGasInfo[this.iNowApGasIdx].cSENS_METHAN_Lev);

      this.zGasInfo[0].fData = ((this.zGasInfo[0].fRealData / this.zGasD_Limit[0]) * 100).toFixed(2);
      this.zGasInfo[1].fData = ((this.zGasInfo[1].fRealData / this.zGasD_Limit[1]) * 100).toFixed(2);
      this.zGasInfo[2].fData = ((this.zGasInfo[2].fRealData / this.zGasD_Limit[2]) * 100).toFixed(2);
      this.zGasInfo[3].fData = ((this.zGasInfo[3].fRealData / this.zGasD_Limit[3]) * 100).toFixed(2);           

      this.$forceUpdate();
    },
    

    checkAlertEvent(){
      this.isAlertEvent = false;
      for (let i = 0; i < this.zLog.length; i++) {        
        if (this.zLog[i].cChecked == "N") {
          this.isAlertEvent = true;
          break;
        }
      }
    },

    SetEventCheckFunc(data){      
      this.$refs.SetEventCheckRef.Show(data.cIdx);
    },

    onSetEventCheck(cIdx){      
      if (cIdx == "-1") {
        this.zLog = [];
      } else {
        for (let i = 0; i < this.zLog.length; i++) {
          if (this.zLog[i].cIdx == cIdx) {
            // this.zLog[i].cChecked = "Y";
            this.zLog.splice(i, 1); 

            this.checkAlertEvent();
            break;
          }
        }
      }
    },
   
    onSelect(data) {            
      this.$refs.SetSOSRef.Show(data.cIdx, data.cTagId, data.cUserId);
    },
    checkData(bShow){    
      if (!this.bCheckAlarm("EV0101")) return;
      var posData = {    
        cChecked : "N",
        cStartDatetime: Util.sFormatDate(Date()) + " 00:00:00",  
        cEndDatetime  : Util.sFormatDate(Date()) + " 23:59:59",
        zMapId : this.UserInfo.zMapIdAll,
        // cGroupId : "SKST",
        cGroupId : this.UserInfo.GroupId,
      };
      axios
        .post(BasicInfo.UIL_API + "GetSOSEventLog", posData, {
          headers: {            
            Authorization: this.$store.state.UserInfo.Token,            
          }
        })
        .then(res => {          
          if (res.data.ResultCd == "44") { EventBus.$emit("onTokenExpired"); return; }

          if (res.data.ResultCd === "00") {
            this.iAlertCount = res.data.Result1.length;            

            
            for (let i = 0; i < this.zApPos.length; i++){
              this.zApPos[i].bAlert = false;
            }

            for (let i = 0; i < this.zApPos.length; i++){              
              if (this.zApPos[i].cBuildingType == 'B') {                
                for (let j = 0; j < res.data.Result1.length; j++){                    
                  if (this.zApPos[i].cMapGroupId == res.data.Result1[j].cMapGroupId && this.zApPos[i].cBuildingId == res.data.Result1[j].cBuildingId){
                    this.zApPos[i].bAlert = true;
                  }
                }
              }
            }

            if (res.data.Result1.length == 0) {
              // EventBus.$emit("onShowMsg", false, "비상호출이 없습니다.");            
            } else {            
              if (bShow) {
                this.$refs.AlertShowSelRef.Show(res.data.Result1);
              }              
            }
            if (res.data.Result2.length > 0) {
              this.iAlertCheckCount = res.data.Result2[0].iCount;              
            }            
          } else {
            EventBus.$emit("onShowMsg", true,  "(" + res.data.ResultCd + ") " + res.data.Msg);
          }
        })
        .catch(err => {
          EventBus.$emit("onErrorSnackbar", "(00)" + err);
        });
    },

    showDevice(data){
      router.push({name: "EventLog", params: {cEvent: data}});
      
    },
    initialize() {             
      this.GetMonitoring();
    },    
    
    goto(item) {
      this.SelectPoi({cType:"MAP", cApId:item.cMapGroupId + item.cBuildingId + item.cMapId });          
    },

    bCheckMapRole(cMapGroupId, cBuildingId, cMapId) {
      let bCheck = false;

      
      for (let i in this.UserInfo.zMapGroupId) {
        if (this.UserInfo.zMapGroupId[i] == "00" || cMapGroupId == "00") {
          bCheck = true;
        } else {
          if (this.UserInfo.zMapGroupId[i] == cMapGroupId) {                
            if (this.UserInfo.zBuildingId[i] == "00" ) {
              bCheck = true;
            } else {
              if (this.UserInfo.zBuildingId[i] == cBuildingId ) {                      
                if (this.UserInfo.zMapId[i] == "00" ) {
                  bCheck = true;
                } else {
                  if (this.UserInfo.zMapId[i] == cMapId ) {
                    bCheck = true;
                  }
                }
              }
            }
          }
        }
      }
      return bCheck;
    },
    
    GetMonitoring() {        
      var posData = {                 
        cNowSiteId : this.UserInfo.NowSiteId,
      };
      axios
        .post(BasicInfo.UIL_API + "GetMonitoring", posData, {
          headers: {
            Authorization: this.$store.state.UserInfo.Token,            
          }
        })
        .then(res => {
          if (res.data.ResultCd == "44") { EventBus.$emit("onTokenExpired"); return; }

          if (!this.isRunCheck) {
            return;
          }


          this.UserCountInfo.fRealtimeCount = res.data.Result2[0].fc4;

          this.option_user.series[0].data[0].value = res.data.Result2[0].fc1;
          this.option_user.series[0].data[1].value = res.data.Result2[0].fc2;
          this.option_user.series[0].data[2].value = res.data.Result2[0].fc3;


          this.option.series[0].data = [];

          var fTot = 0;
          for(let i = 0; i < res.data.Result3.length; i++){
            if (i < 5) {
              fTot = fTot + res.data.Result3[i].fc1;
              let a ={ value: res.data.Result3[i].fc1, name: res.data.Result3[i].cDeviceTypeNm };
              this.option.series[0].data.push(a);
            }
          }

          let b = {
            value: fTot,
              itemStyle: {
                // stop the chart from rendering this piece
                color: 'none',
                decal: {
                  symbol: 'none'
                }
              },
              label: {
                show: false
              }
          };
          this.option.series[0].data.push(b);

          
          this.zApGasInfo = res.data.Result1;
          // console.log(this.zApGasInfo);

          this.zGasDevice_Info = [];

          for(let i = 0; i < this.zApGasInfo.length; i++) {
            var a = {
              cCaption : this.zApGasInfo[i].cSensorId2 + " 가스센서 > " + this.zApGasInfo[i].cMapFullNm,
              cSensorId : this.zApGasInfo[i].cSensorId,
              Data: [
                { cCaption: '<div class="d-flex mt-1 mb-n2">O<p style="font-size:5px; margin-top: 5px;">2</p></div>', cNm : "산소", cInfo : "(안전범위:18.5~22.5 %)", fData: parseFloat(this.zApGasInfo[i].cSENS_OXYGEN_Data), fRealData : parseFloat(this.zApGasInfo[i].cSENS_OXYGEN_Data), cUnit:"%", cState : this.sGetStateNm(0, this.zApGasInfo[i].cSENS_OXYGEN_Lev)},
                { cCaption: '<div class="d-flex mt-1 mb-n2">H<p style="font-size:5px; margin-top: 5px;">2</p>S</div>', cNm : "일산화탄소",  cInfo : "(안전범위:10ppm미만)", fData : parseFloat(this.zApGasInfo[i].cSENS_CARBON_MONOXIDE_Data), fRealData : parseFloat(this.zApGasInfo[i].cSENS_CARBON_MONOXIDE_Data), cUnit:"ppm",  cState : this.sGetStateNm(1, this.zApGasInfo[i].cSENS_CARBON_MONOXIDE_Lev)},
                { cCaption: '<div class="d-flex">CO</div>', cNm : "황화수소",  cInfo : "(안전범위:10ppm미만)", fData : parseFloat(this.zApGasInfo[i].cSENS_HYDROGEN_Data), fRealData : parseFloat(this.zApGasInfo[i].cSENS_HYDROGEN_Data), cUnit:"ppm",cState : this.sGetStateNm(2, this.zApGasInfo[i].cSENS_HYDROGEN_Lev)},
                { cCaption: '<div class="d-flex mt-1 mb-n2">CH<p style="font-size:5px; margin-top: 5px;">4</p></div>', cNm : "메탄",  cInfo : "(안전범위:10%미만)", fData : parseFloat(this.zApGasInfo[i].cSENS_METHAN_Data), fRealData : parseFloat(this.zApGasInfo[i].cSENS_METHAN_Data), cUnit:"%",cState : this.sGetStateNm(3, this.zApGasInfo[i].cSENS_METHAN_Lev)},
              ]
            }
            this.zGasDevice_Info.push(a);
          }


          if (this.zApGasInfo.length == 0) {
            this.iNowApGasIdx = -1;
          } else {
            if (this.iNowApGasIdx == -1) {
              this.iNowApGasIdx = 0;              
            }
            this.setGasApIdx(this.iNowApGasIdx);            
          }
          
          if (res.data.Result9.length > 0) {
            try {
              const Object = JSON.parse(res.data.Result9[0].cData);
              console.log(res.data.Result9[0].cData, Object);  

              this.AWS_Info.fTemperature = Object.TMP;
              this.AWS_Info.fHumidity = Object.REH;
              this.AWS_Info.fFall = Object.PCP;
              this.AWS_Info.fWindSpeed = Object.WSD;
              this.AWS_Info.fWindDirection = this.getWindDirection(Object.VEC);
            } catch (error) {
              console.log(error);              
            }            
          }
        })
        // .catch(err => {
        //   EventBus.$emit("onErrorSnackbar", "(03)"+err);
        // });
      //
    },   

    getWindDirection(vec) {
      if (vec >= 337.5 || vec < 22.5) {
        return "북풍";
      } else if (vec >= 22.5 && vec < 67.5) {
        return "북동풍";
      } else if (vec >= 67.5 && vec < 112.5) {
        return "동풍";
      } else if (vec >= 112.5 && vec < 157.5) {
        return "남동풍";
      } else if (vec >= 157.5 && vec < 202.5) {
        return "남풍";
      } else if (vec >= 202.5 && vec < 247.5) {
        return "남서풍";
      } else if (vec >= 247.5 && vec < 292.5) {
        return "서풍";
      } else if (vec >= 292.5 && vec < 337.5) {
        return "북서풍";
      } else {
        return "알 수 없음"; // 예외 처리
      }
    },

    goSOS(){
      router.push({name: "EventLog"});      
    },
    onSetSOS(cUserId) {
      if (this.isMapMode) {
        this.$refs.MapWindowViewRef.SetSOS(cUserId, false);
      }
      this.checkData();
    },    

    sGetStateNm(type, s) {
      if (type == 0) { //산소
        if (s == "0") {
          return "일반";
        } else if (s == "1") {
          return "1차경보";
        } else if (s == "2") {
          return "2차경보";
        } else if (s == "5") {
          return "센서예열";
        } else if (s == "6") {
          return "범퍼테스트";
        } else if (s == "7") {
          return "3차경보";
        } else {
          return s;
        }
      } else if (type == 1) { //일산화 탄소
        if (s == "0") {
          return "일반";
        } else if (s == "1") {
          return "1차경보";
        } else if (s == "2") {
          return "2차경보";
        } else if (s == "3") {
          return "STEL경보";
        } else if (s == "4") {
          return "TWA경보";
        } else if (s == "5") {
          return "센서예열";
        } else if (s == "6") {
          return "범퍼테스트";
        } else {
          return s;
        }
      } else if (type == 2) { //황화수소
        if (s == "0") {
          return "일반";
        } else if (s == "1") {
          return "1차경보";
        } else if (s == "2") {
          return "2차경보";
        } else if (s == "5") {
          return "센서예열";
        } else if (s == "6") {
          return "범퍼테스트";
        } else {
          return s;
        }
      } else if (type == 3) { //메탄
        if (s == "0") {
          return "일반";
        } else if (s == "1") {
          return "1차경보";
        } else if (s == "2") {
          return "2차경보";
        } else if (s == "5") {
          return "센서예열";
        } else if (s == "6") {
          return "범퍼테스트";
        } else {
          return s;
        }
      } else if (type == 4) { //이산화탄소
        if (s == "0") {
          return "일반";
        } else if (s == "1") {
          return "1차경보";
        } else if (s == "2") {
          return "2차경보";
        } else if (s == "5") {
          return "센서예열";
        } else if (s == "6") {
          return "범퍼테스트";
        } else {
          return s;
        }
      } else {      
        if (s == "D") {
          return "위험";
        } else if (s == "W") {
          return "경고";
        } else if (s == "N") {
          return "안전";
        } else {
          return s;
        }
      }
    },


    goSensorState(cSensorId){
      EventBus.$emit("onSensorLog", cSensorId);
    },

  }
};
</script>

<style>
/* <style scoped> */

.alert_box {  
  z-index: 12;
  position: absolute;
  margin: auto;
  width : 520px;
  top: 250px;
  left: 50%;
  transform: translate(-50%,-50%);
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 10px;
  justify-content: center;
  background: #EE3300;    
  text-align: center;    
  color: white;
  border-radius: 10px;  
  font-size : 26px;
  font-weight: bold;
  /* text-shadow: -2px 0 #EE3300,0 2px #EE3300,2px 0 #EE3300,0 -2px #EE3300; */
  box-shadow: 0 0 50px #555555;
}


.event_box {  
  z-index: 12;
  position: absolute;
  margin: auto;
  width : 520px;
  top: 350px;
  left: 50%;
  transform: translate(-50%,-50%);
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 10px;
  justify-content: center;
  background: #EE3300;    
  text-align: center;    
  color: white;
  border-radius: 10px;  
  font-size : 26px;
  font-weight: bold;
  /* text-shadow: -2px 0 #EE3300,0 2px #EE3300,2px 0 #EE3300,0 -2px #EE3300; */
  box-shadow: 0 0 50px #555555;
}

.check-button{
  position: relative;  
  left: 110px;
  top: -18px;
  width : 50px;
  height : 0px;  
}

#blinking{
	-webkit-animation:blink 1s ease-out infinite alternate;
    -moz-animation:blink 1s ease-out infinite alternate;
    animation:blink 1s ease-out infinite alternate;
}
@-webkit-keyframes blink{
    0% {opacity:0;}
    100% {opacity:1;}
}
@-moz-keyframes blink{
    0% {opacity:0;}
    100% {opacity:1;}
}
@keyframes blink{
    0% {opacity:0;}
    100% {opacity:1;}
} 


.box_sel_info_floor{
  z-index: 10;
  position:fixed;   
  top : 93px;
  left : calc(100vw - 730px);
  width : 400px;
  height : 500px;
  border-radius: 8px;  
  color: #000000EE;
}

.box_sel_info_building{
  z-index: 10;  
  position:fixed;   
  top : 93px;
  left : calc(100vw - 830px);
  width : 500px;
  height : 500px;  
  /* background: #FFFFFF; */  
  color: #000000EE;
  /* box-shadow: 2px 2px 1px 1px #55555522; */
}



.v-treeview-node__root{
  min-height: 10px !important;     
  height: 25px !important;     
  padding: 0px;  
  margin: 0px;
}



.tab {
  float: left;
  height: 31px;
  margin-top: 5px;
  margin-right: 5px;
  /* margin: 0 8px -1px 0; */
  border: 1px solid #DAE0E7;
  background: #FFFFFF;
  /* background: #F9F9F9; */
  border-radius: 8px 8px 0 0;
  overflow: hidden;
  font-size: 14px;
  /* transition: all .8s; */
}

.tab:hover {
  background: #fff;  
}



table {
  width: 100%;    
  font-size: 14px;
}
.td {
  height: 33px;      
}
.ARight {
  text-align: right;      
}
.td_bold {
  font-size: 20px;
  font-weight: bolder;
  /* color: #3D7EEF; */
}
.title_card {
  margin-left : 5px;
  font-size: 22px;
  font-weight: bold;
  /* color: #3D7EEF; */
}

.card_style {
  height : 230px;
}
.tc_outline {
  height : 120px;
  width : 150px;
  border-radius: 20px;
  border: 1px solid #DAE0E7;  
  text-align: center;
}

.gas_card {
  height : 355px;  
  border-radius: 15px;
  border: 1px solid #DAE0E7;  
  text-align: center;
}

.percent {
  opacity: 0.0 !important;
  color : red;
}

.percent__int {
  opacity: 0.0 !important;
  color : red;
}

.chart {
  height: 230px;
  width: 100%;
}  

.chart_user{
  height: 200px;
  width: 100%;
}  

.chart_noise{
  height: 200px;
  width: 50%;
}  


.heartbeat {
	-webkit-animation: heartbeat 1.5s ease-in-out infinite both;
  animation: heartbeat 1.5s ease-in-out infinite both;
}


 @-webkit-keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: center center;
            transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(1.02);
            transform: scale(1.02);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(1.06);
            transform: scale(1.06);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(1.01);
            transform: scale(1.01);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}
@keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: center center;
            transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(1.02);
            transform: scale(1.02);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(1.06);
            transform: scale(1.06);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(1.01);
            transform: scale(1.01);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}




.heartbeat2 {
	-webkit-animation: heartbeat2 1.5s ease-in-out infinite both;
  animation: heartbeat2 1.5s ease-in-out infinite both;
}


 @-webkit-keyframes heartbeat2 {
  from {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: center center;
            transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(1.04);
            transform: scale(1.04);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(1.30);
            transform: scale(1.30);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(1.03);
            transform: scale(1.03);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}
@keyframes heartbeat2 {
  from {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: center center;
            transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(1.04);
            transform: scale(1.04);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(1.30);
            transform: scale(1.30);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(1.03);
            transform: scale(1.03);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}



.box_map_left{
  overflow-x: auto; 
  overflow-y: auto; 
  width:100%; 
  height: calc(100vh - 390px);   
  padding:10px; 
  background-color:#ffffff;
}

.box_map_left2{
  overflow-x: auto; 
  overflow-y: auto; 
  width:100%; 
  height: calc(100vh - 172px); 
  padding:10px; 
  background-color:#ffffff;

}


.box_map_left::-webkit-scrollbar {
    width: 10px; 
}
 
.box_map_left::-webkit-scrollbar-thumb {
    background : #FFFFFFFF;
}

.box_map_left::-webkit-scrollbar-track {
    background: #FFFFFF33; /*스크롤바 트랙 색상*/
}
</style>





